<template>
  <v-container fluid class="transparent pa-0">
    <header class="blue lighten-5 pa-2">
      <v-row justify="space-between">
        <v-col cols="12" sm="6" md="5" class="d-flex">
          <img
            src="@/assets/images/fli_logo.png"
            class="welcome-image mb-2"
            alt="fastlog"
            @click="$router.push('/')"
          >
          <v-text-field
            :placeholder="$_strings.home.PLACEHOLDER_ORDER_NO"
            dense
            hide-details
            outlined
            class="caption mx-4"
            v-model="search"
            @keyup.enter="search && fetchDetail(search)"
          >
            <template v-slot:prepend-inner>
              <v-icon @click="search && fetchDetail(search)">
                mdi-magnify
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex pl-5 pl-sm-0 flex-column flex-sm-row justify-start justify-sm-end align-sm-center">
          <h5>Customer: <span class="primary--text">(021) 2927 8888</span></h5>
          <h5 class="ml-sm-4">Email: <span class="primary--text">customercare@fastanalogistic.com</span></h5>
        </v-col>
      </v-row>
    </header>
    <common-error
      v-if="isError"
      @fetchData="fetchDetail"
    />
    <section v-else-if="isLoading" class="mt-4">
      <v-skeleton-loader
        class="mx-auto"
        type="heading"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="mx-auto"
        type="list-item-two-line, card, list-item-two-line"
      ></v-skeleton-loader>
    </section>
    <main v-else class="pa-4 transparent">
      <v-row>
        <v-col cols="12">
          <h2>Detail Pesanan/Kiriman</h2>
          <v-divider class="my-2"></v-divider>
        </v-col>
        <v-col cols="12">
          <h3>{{$route.params.orderNo || '-'}}</h3>
        </v-col>
        <v-col cols="12">
          <h4>Data Armada</h4>
          <v-divider class="mt-2"></v-divider>
        </v-col>
        <v-col cols="12" class="">
          <v-expansion-panels
            v-for="(shipment, index) in shipments"
            :key="index"
            flat
            class="mt-2 border"
          >
            <v-expansion-panel class="grey lighten-4 border d-relative pa-0 ma-0">
              <v-expansion-panel-header>
                <v-row
                  justify="space-between"
                  align="center"
                >
                  <v-col class="pa-0">
                    <p class="ma-0 ml-3 font-weight-medium">{{shipment.title}}</p>
                  </v-col>
                  <v-col class="pt-0 pb-0 d-flex justify-end">
                    <v-chip
                      :color="getColorByStatus(shipment.latestStatus).background"
                      :class="getColorByStatus(shipment.latestStatus).textColor"
                      label
                    >
                      {{shipment.latestStatus}}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="white pa-0 ma-0">
                <v-row class="mt-4">
                  <v-col cols="12" sm="6">
                    <v-row>
                      <v-col cols="6">
                        <p class="ma-0 pa-0 text-subtitle-2 grey--text">Tipe Mobil</p>
                        <p>{{shipment.transportTypes && shipment.transportTypes.name || '-'}}</p>
                        <p class="ma-0 pa-0 text-subtitle-2 grey--text">Nama Sopir</p>
                        <p>{{shipment.driverName || '-'}}</p>
                      </v-col>
                      <v-col cols="6">
                        <p class="ma-0 pa-0 text-subtitle-2 grey--text">No Kendaraan</p>
                        <p>{{shipment.licensePlate || '-'}}</p>
                        <p class="ma-0 pa-0 text-subtitle-2 grey--text">Kontak Sopir</p>
                        <p>{{shipment.driverPhone || '-'}}</p>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col v-if="shipment.serviceType === 'LCL'" cols="12" sm="6">
                    <v-row>
                      <v-col cols="auto">
                        <p class="ma-0 pa-0 text-subtitle-2 grey--text">Kubikasi</p>
                        <p>{{shipment.cubic}}</p>
                      </v-col>
                      <v-col cols="auto">
                        <p class="ma-0 pa-0 text-subtitle-2 grey--text">Tonase</p>
                        <p>{{shipment.tonase}}</p>
                      </v-col>
                      <v-col cols="auto">
                        <p class="ma-0 pa-0 text-subtitle-2 grey--text">Karton</p>
                        <p>{{shipment.carton}}</p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <h4>{{shipment.shipperName || '-'}}</h4>
                    <v-divider class="my-2"></v-divider>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12" sm="6">
                        <h5><v-icon color="light-blue">mdi-map-marker-radius</v-icon>Lokasi Asal</h5>
                        <p class="mt-2 mb-0">{{shipment.origin.locationName || '-'}}</p>
                        <p class="text-caption grey--text">{{shipment.origin.locationAddress || '-'}}</p>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <p class="ma-0 pa-0 text-subtitle-2 grey--text">Nama Pengirim</p>
                            <p class="text-body-2">{{shipment.originSenderName || '-'}}</p>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <p class="ma-0 pa-0 text-subtitle-2 grey--text">No Telepon Pengirim</p>
                            <p class="text-body-2">{{shipment.originSenderPhone || '-'}}</p>
                          </v-col>
                          <v-col cols="12">
                            <p class="ma-0 pa-0 text-subtitle-2 grey--text">Informasi Tambahan</p>
                            <p class="text-body-2">{{shipment.originNote || '-'}}</p>
                          </v-col>
                        </v-row>
                        <section v-if="shipment.shipmentsDetail.find((x) => x.pickupDropType === 'PICKUP')" cols="12" sm="4">
                          <v-divider class="my-8"></v-divider>
                          <div
                            v-for="(shipmentDetail, shipmentDetailIndex) in shipment.shipmentsDetail.filter((x) => x.pickupDropType === 'PICKUP')"
                            :key="shipmentDetailIndex">
                            <h5><v-icon color="green" >mdi-map-marker-radius</v-icon>Lokasi Multipick - {{shipmentDetailIndex + 1}}</h5>
                            <p class="mt-2 mb-0">{{shipmentDetail.locationName || '-'}}</p>
                            <p class="text-caption grey--text">{{shipmentDetail.cityName || '-'}}</p>
                            <v-row>
                              <v-col cols="12" sm="6">
                                <p class="ma-0 pa-0 text-subtitle-2 grey--text">Nama Pengirim</p>
                                <p class="text-body-2">{{shipmentDetail.picName || '-'}}</p>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <p class="ma-0 pa-0 text-subtitle-2 grey--text">No Telepon Pengirim</p>
                                <p class="text-body-2">{{shipmentDetail.picPhoneNo || '-'}}</p>
                              </v-col>
                              <v-col cols="12">
                                <p class="ma-0 pa-0 text-subtitle-2 grey--text">Informasi Tambahan</p>
                                <p class="text-body-2">{{shipmentDetail.picNote || '-'}}</p>
                              </v-col>
                            </v-row>
                          </div>
                        </section>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <h5><v-icon color="yellow accent-4">mdi-map-marker-radius</v-icon>Lokasi Tujuan</h5>
                        <p class="mt-2 mb-0">{{shipment.destination.locationName || '-'}}</p>
                        <p class="text-caption grey--text">{{shipment.destination.locationAddress || '-'}}</p>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <p class="ma-0 pa-0 text-subtitle-2 grey--text">Nama Penerima</p>
                            <p class="text-body-2">{{shipment.destinationReceiverName || '-'}}</p>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <p class="ma-0 pa-0 text-subtitle-2 grey--text">No Telepon Penerima</p>
                            <p class="text-body-2">{{shipment.destinationReceiverPhone || '-'}}</p>
                          </v-col>
                          <v-col cols="12">
                            <p class="ma-0 pa-0 text-subtitle-2 grey--text">Informasi Tambahan</p>
                            <p class="text-body-2">{{shipment.destinationNote || '-'}}</p>
                          </v-col>
                        </v-row>
                        <section v-if="shipment.shipmentsDetail.find((x) => x.pickupDropType === 'DROP')" cols="12" sm="4">
                          <v-divider class="my-8"></v-divider>
                          <div
                            v-for="(shipmentDetail, shipmentDetailIndex) in shipment.shipmentsDetail.filter((x) => x.pickupDropType === 'DROP')"
                            :key="shipmentDetailIndex">
                            <h5><v-icon color="red accent-4">mdi-map-marker-radius</v-icon>Lokasi Multidrop - {{shipmentDetailIndex + 1}}</h5>
                            <p class="mt-2 mb-0">{{shipmentDetail.locationName || '-'}}</p>
                            <p class="text-caption grey--text">{{shipmentDetail.cityName || '-'}}</p>
                            <v-row>
                              <v-col cols="12" sm="6">
                                <p class="ma-0 pa-0 text-subtitle-2 grey--text">Nama Penerima</p>
                                <p class="text-body-2">{{shipmentDetail.picName || '-'}}</p>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <p class="ma-0 pa-0 text-subtitle-2 grey--text">No Telepon Penerima</p>
                                <p class="text-body-2">{{shipmentDetail.picPhoneNo || '-'}}</p>
                              </v-col>
                              <v-col cols="12">
                                <p class="ma-0 pa-0 text-subtitle-2 grey--text">Informasi Tambahan</p>
                                <p class="text-body-2">{{shipmentDetail.picNote || '-'}}</p>
                              </v-col>
                            </v-row>
                          </div>
                        </section>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-divider class="my-8"></v-divider>
                        <h5>Kategori Barang</h5>
                        <p class="mt-2 mb-0">{{shipment.categoryName || '-'}}</p>
                        <p class="text-caption grey--text">{{shipment.subCategoryName || '-'}}</p>
                        <v-row>
                          <v-col cols="12">
                            <p class="ma-0 pa-0 text-subtitle-2 grey--text">Informasi Tambahan</p>
                            <p class="text-body-2">{{shipment.goodsNote || '-'}}</p>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-divider class="my-8"></v-divider>
                        <h5>Catatan Tambahan</h5>
                        <p class="mt-2 mb-0 caption">{{shipment.additionalNote || '-'}}</p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-divider class="my-4"></v-divider>
                <v-row>
                  <v-col cols="12" sm="12" md="4">
                    <v-card outlined>
                      <v-card-title>
                        <h5>Status Pesanan</h5>
                      </v-card-title>
                      <v-card-text>
                        <section style="margin-left:-40px">
                          <v-timeline
                            v-for="(shipmentStatus, index) in shipment.status"
                            dense
                            align-top
                            :key="index">
                            <v-timeline-item color="blue lighten-2" fill-dot small right>
                              <v-row>
                                <v-col class="ma-0 pa-0">
                                  <p class="grey--text mb-0">
                                    {{dateFormat(shipmentStatus.statusDateTime)}}
                                  </p>
                                  <p class="caption black--text mb-0">{{shipmentStatus.status}}</p>
                                  <p class="caption grey--text">{{shipmentStatus.notes}}</p>
                                </v-col>
                                <v-col cols="auto" class="grey--text">{{timeFormat(shipmentStatus.statusDateTime)}}</v-col>
                              </v-row>
                            </v-timeline-item>
                          </v-timeline>
                        </section>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="12" md="8">
                    <v-card outlined>
                      <v-card-title>
                        <h5>Pelacakan Pesanan</h5>
                      </v-card-title>
                      <v-card-text>
                        <GmapMap
                          :center='center'
                          :zoom='5'
                          map-type-id="terrain"
                          style='width:100%;  height: 400px;'
                        >
                          <GmapMarker
                            :position="{
                              lat: shipment.origin.latitude,
                              lng: shipment.origin.longitude
                            }"
                            :clickable="true"
                            :icon="{url : require('@/assets/images/map-marker-origin.png')}"
                          />
                          <GmapMarker
                            :position="{
                              lat: shipment.destination.latitude,
                              lng: shipment.destination.longitude
                            }"
                            :clickable="true"
                            :icon="{url : require('@/assets/images/map-marker-destination.png')}"
                          />

                          <section v-if="shipment.driverLatitude && shipment.driverLongitude">
                            <GmapMarker
                              :position="{
                                lat: shipment.driverLatitude,
                                lng: shipment.driverLongitude
                              }"
                              :clickable="true"
                              :icon="{url : require('@/assets/images/yellow-truck-map-marker-icon.png')}"
                            />
                          </section>
                          <section
                            v-if="shipment.shipmentsDetail.find((x) => x.pickupDropType === 'PICKUP' && x.latitude && x.longitude)"
                          >
                            <GmapMarker
                              v-for="(multipick, index) in shipment.shipmentsDetail.filter((x) => x.pickupDropType === 'PICKUP')" :key="index"
                              :position="{
                                lat: multipick.latitude,
                                lng: multipick.longitude
                              }"
                              :clickable="true"
                              :icon="{url : require('@/assets/images/map-marker-multipick.png')}"
                            />
                          </section>
                          <section v-if="shipment.shipmentsDetail.find((x) => x.pickupDropType === 'DROP' && x.latitude && x.longitude)">
                            <GmapMarker
                              v-for="(multidrop, index) in shipment.shipmentsDetail.filter((x) => x.pickupDropType === 'DROP')" :key="index"
                              :position="{
                                lat: multidrop.latitude,
                                lng: multidrop.longitude
                              }"
                              :clickable="true"
                              :icon="{url : require('@/assets/images/map-marker-multidrop.png')}"
                            />
                          </section>
                        </GmapMap>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </main>
  </v-container>
</template>

<script>
import { dateFormat, timeFormat } from '@/helper/commonHelpers';

export default {
  created() {
    const { data, orderNo } = this.$route.params;
    if (!orderNo) this.$router.replace('/');
    if (!data) {
      this.fetchDetail();
      return;
    }
    this.setData(data);
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      search: '',
      shipments: [],
      center: { lat: -6.1753917, lng: 106.82715329999996 },
    };
  },
  methods: {
    dateFormat,
    timeFormat,
    setData(data) {
      this.shipments = data;
    },
    getColorByStatus(status) {
      switch (status.toLowerCase()) {
        case 'dibatalkan':
        case 'kedaluwarsa':
          return {
            background: 'red',
            textColor: 'white--text',
          };
        default:
          return {
            background: 'cyan lighten-5',
            textColor: 'cyan--text',
          };
      }
    },
    loading(boolean) {
      if (boolean && this.search) {
        this.$root.$loading.show();
        return;
      }
      if (boolean) {
        this.isLoading = true;
        return;
      }
      this.$root.$loading.hide();
      this.isLoading = false;
    },
    async fetchDetail(orderNo = this.$route.params.orderNo) {
      try {
        this.loading(true);
        this.isError = false;
        const result = await this.$_services.tracking.fetchOrder(orderNo);
        this.setData(result.data);
        if (this.search && (this.$route.params.orderNo !== this.search)) {
          this.$router.replace({
            params: {
              orderNo: this.search,
            },
          });
        }
      } catch (err) {
        if (err.response.status >= 500) {
          this.isError = true;
        }
      } finally {
        this.loading(false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .welcome {
    &-image {
      cursor: pointer;
      height: 40px;
    }
  }
  .border {
    border: 1px solid rgb(224, 224, 224);
  }
</style>
