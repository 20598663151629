import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

/**
 * parse unix timestamp to date dd MMM YYYY
 * @param {Number} t Unix TimeStamp
 */
export const dateFormat = (t) => {
  if (!t) return '-';
  const monthNames = [
    'Jan', 'Feb', 'Mar',
    'Apr', 'Mei', 'Jun',
    'Jul', 'Agu', 'Sep',
    'Okt', 'Nov', 'Des',
  ];
  const date = new Date(t);
  const getDay = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const day = getDay < 10 ? `0${getDay}` : getDay;
  return `${day} ${monthNames[monthIndex]} ${year}`;
};

/**
 * parse date to HH:mm
 * @param {Number} t date
 */
export const timeFormat = (date) => dayjs(date).format('HH:mm');
